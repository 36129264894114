@tailwind base;
@tailwind components;
@tailwind utilities;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body{
  background-color: black;
}

.regContainer {
  width: 100%;
height: 100%;
background: linear-gradient(rgba(0, 150, 255, 0.5), rgba(0, 0, 0, 0.5)),
	url('https://i.postimg.cc/Hkh6PLXN/LDA-Logo-Blue2nooffset.png') center;
background-size: cover;
/* display: flex; */
align-items: center;
justify-content: center;
}
.regWrapper {
  background-color: rgba(83, 77, 77, 0.8);
}
.info {
  background-color: rgba(0, 0, 0, 0.2);
}
.icon{
  border-radius: 50%;
}

.card-cont{
  flex: 1;
	margin-bottom: 1rem;
	min-width: 18rem;
	max-width: 20rem;
	height: 27rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: black;
	position: relative;
}
.ftCard-info{
  opacity: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 3;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.5s ease;
	cursor: pointer;
}
.card-icon {
  width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px;
	transition: all 0.5s ease;
	
}
.card-icon:hover {
  background-color: #e9f5f5;
		transform: scale(1.1);
}