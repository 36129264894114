:root {
  --background-color: black;
  --text-color: hsl(0, 0%, 100%);
  --orange:  rgb(49, 163, 239)
}
h2{
  color: white;
}
.wrapper {
  display: grid;
  place-content: center;
  /* background-color: var(--background-color); */
  /* min-height: 100vh; */
  height: 26vh;
  /* font-family: "Oswald", sans-serif; */
  font-size: clamp(0.5rem, 2rem + 6vw, 6rem);
  font-weight: 700;
  text-transform: uppercase;
  color: var(--orange);
}

.wrapper > div {
  grid-area: 1/1/-1/-1;
}
.top {
  clip-path: polygon(0% 0%, 100% 0%, 100% 48%, 0% 58%);
}
.bottom {
  clip-path: polygon(0% 60%, 100% 50%, 100% 100%, 0% 100%);
  color: transparent;
  background: -webkit-linear-gradient(110deg, black 53%, var(--text-color) 65%);
  background: linear-gradient(177deg, rgb(0, 0, 0) 53%, var(--text-color) 65%);
  background-clip: text;
  -webkit-background-clip: text;
  transform: translateX(-0.02em);
}