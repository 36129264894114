.userShow   {
  flex: 1;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgb(11, 128, 238);
  box-shadow: 0px 0px 15px -10px rgb(7, 141, 231);
  background-color: rgb(61, 61, 62)

}
.userPromo{
  flex: 1;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgb(11, 128, 238);
  box-shadow: 0px 0px 15px -10px rgb(7, 141, 231);

}

.order{
  background-color: rgb(61, 61, 62)
}

.userShowTop {
  display: flex;
  align-items: center;
}

.userShowImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.userShowTopTitle {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.userShowUsername {
  font-weight: 600;
}

.userShowUserTitle {
  font-weight: 300;
}

.userShowBottom{
    margin-top: 20px;
}

.userShowTitle {
  font-size: 14px;
  font-weight: 600;
  color: rgb(175, 170, 170);
}

.userShowInfo{
    display: flex;
    align-items: center;
    margin: 20px 0px;
    color: #3480f1;
}

.userShowIcon{
    font-size: 16px !important;
}

.userShowInfoTitle{
    margin-left: 10px;
}

.userPromo{
  flex: 2;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  margin-left: 20px;
}

