.sidebar {
  flex: 1;
  height: 100rem;
  background-color: rgb(61, 61, 62);
  position:sticky;
  top: 50px;
  /* width: 17%; */
  max-width: 10%;
  /* min-width: 25%; */
}

.sidebarWrapper {
  padding: 0.5rem;
  /* min-width: 40%; */
  color: #555;
  
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 13px;
  color: rgb(187, 186, 186);
}

.sidebarList {
  list-style: none;
  /* padding: 5px; */
}

.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  color:rgb(59, 143, 245);
}

.sidebarListItem.active,
.sidebarListItem:hover {
  background-color: rgb(240, 240, 255);
}

.sidebarIcon{
    margin-right: 5px;
    font-size: 20px !important;
}
